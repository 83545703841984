<template>
  <div class="admin-guidance">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Guidance
      </h1>
    </div>
    <!-- / Page Header -->

    <!-- Menu -->
    <div class="menu">
      <a-row type="flex" :gutter="20">
        <a-col :span="colSpan" v-for="(item, i) in menuItems" :key="i">
          <admin-menu-item
            :img="item.img"
            :title="item.title"
            @click.native="() => handleItemClicked(item)"
          ></admin-menu-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Menu -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminMenuItem from "../../components/Admin/AdminMenuItem.vue";
export default {
  components: {AdminMenuItem},
  data() {
    return {
      windowWidth: window.innerWidth,

      menuItems: [
        {
          title: "Topics",
          description: "Optionally group your articles into topics so they're easier to read",
          onClick: () => {
            this.$router.push("/admin/guidance/topics");
          },
          img: "/admin/topics.jpg",
        },
          {
          title: "Articles",
          description: "Create web articles in the teamSOS app for your staff to read",
          onClick: () => {
            this.$router.push("/admin/guidance/articles");
          },
          img: "/admin/articles.jpg",
        },
           {
          title: "Help Videos",
          description: "Upload or link to videos to inform staff or support process",
          onClick: () => {
            this.$router.push("/admin/guidance/help-videos");
          },
          img: "/admin/video.jpg",
        },
           {
          title: "Playbooks",
          description: "Attach your existing playbooks so staff can familiarise themselves with them early",
          onClick: () => {
            this.$router.push("/admin/guidance/playbooks");
          },
          img: "/admin/playbook.jpg",
        },
             {
          title: "Downloads",
          description: "Share policy documents and other files",
          onClick: () => {
            this.$router.push("/admin/guidance/downloads");
          },
          img: "/admin/download.jpg",
        },
                {
          title: "Links",
          description: "Link to external web resources that support your policies and processes",
          onClick: () => {
            this.$router.push("/admin/guidance/external-links");
          },
          img: "/admin/link.jpg",
        }
      ],
    };
  },

  methods: {
    handleItemClicked(item) {
      if (item.onClick) {
        item.onClick();
      }
    },
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
    }),

    colSpan() {
      if (this.windowWidth > 1200) {
        return 8;
      }
      if (this.windowWidth > 800) {
        return 12;
      }
      if (this.windowWidth > 500) {
        return 24;
      }
      return 24;
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  margin-top: 20px;
}

.admin-menu-item {
  margin-bottom: 15px;
}
</style>